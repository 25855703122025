<template>
  <div>
    <p v-if="logoutError">Could not logout, try again.</p>
    <a class="logout-link" href="#" @click.prevent="logout">
      {{ isLoggingOut ? "Logging out..." : "Logout" }}
    </a>
  </div>
</template>

<script>
import { logout } from "@/api/authApi.js";
import { API_STATUS } from "@/constants/apiStatus.js";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "Logout",
  props: {
    afterLogoutUrl: {
      type: String,
      default: "/",
    },
  },
  data() {
    return {
      logoutApiStatus: IDLE,
    };
  },
  computed: {
    logoutError() {
      return this.logoutApiStatus === ERROR;
    },
    isLoggingOut() {
      return this.logoutApiStatus === PENDING;
    },
  },
  methods: {
    async logout() {
      try {
        if (this.isLoggingOut) return;
        this.logoutApiStatus === PENDING;
        await logout();
        this.logoutApiStatus === SUCCESS;
        this.$emit("logout");
        this.$router.push(this.afterLogoutUrl);
      } catch (error) {
        console.error(error);
        this.logoutApiStatus === ERROR;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-link {
  color: #373a3c;
  font-weight: 600;
}
</style>