import { functions } from '@/config/firebase.config';

export const fetchIsrcData = (isrc_ids) => {
  return functions
    .httpsCallable('fetchIsrcDataSummary')({ isrc_ids })
    .then(({ data }) => data.data);
};

export const fetchBulkApplePlaylists = (urls) => {
  return functions
    .httpsCallable('fetchBulkApplePlaylists')({ urls })
    .then(({ data }) => data.data);
};

export const fetchBulkSpotifyPlaylists = (urls) => {
  return functions
    .httpsCallable('fetchBulkSpotifyPlaylists')({ urls })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchBulkPlaylists = ({
  apple_urls,
  spotify_urls,
  type = 'summary',
}) => {
  return functions
    .httpsCallable('fetchBulkPlaylistsSummary')({
      apple_urls,
      spotify_urls,
      type,
    })
    .then(({ data }) => data.data);
};

export const fetchPlaylists = ({ isrc_ids, productId, query, page, count }) => {
  return functions
    .httpsCallable('fetchPlaylists')({
      isrc_ids,
      productId,
      query,
      page,
      count,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const processFileUploadIsrcCodes = ({ isrc_ids, fileName }) => {
  return functions
    .httpsCallable('isrcCodesFileUpload', {
      timeout: 9999999999,
    })({ isrc_ids, fileName })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchUserIsrcUploads = () => {
  return functions
    .httpsCallable('fetchUserIsrcUploads')()
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const deleteUserIsrcUpload = (fileUploadedId) => {
  return functions
    .httpsCallable('deleteUserIsrcUpload')({ fileUploadedId })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const searchIsrcUploadResults = ({ fileUploadedId, query, page }) => {
  return functions
    .httpsCallable('searchIsrcUploadResults')({ fileUploadedId, query, page })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchTracksDetails = (fileUploadedId, isrc_ids) => {
  return functions
    .httpsCallable('fetchTracksDetails')({ fileUploadedId, isrc_ids })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchPlaylistDetails = (isrc_ids, productId) => {
  return functions
    .httpsCallable('fetchPlaylistDetails')({ isrc_ids, productId })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchIsrcUploadedFileData = ({ fileUploadedId }) => {
  return functions
    .httpsCallable('fetchIsrcUploadedFileData')({ fileUploadedId })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const checkIfFileProcessingFinished = ({ fileUploadedId, userId }) => {
  return functions
    .httpsCallable('checkIfFileProcessingFinished')({ fileUploadedId, userId })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const scheduleFileUploadReprocess = ({ fileUploadedId }) => {
  return functions
    .httpsCallable('scheduleIsrcFileProcessing', {
      timeout: 9999999999,
    })({ fileUploadedId })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const getHfaCodesByIsrcId = (fileUploadedId, isrc_ids) => {
  return functions
    .httpsCallable('getHfaCodesByIsrcId')({ fileUploadedId, isrc_ids })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};
