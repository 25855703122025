<template>
  <div :class="$style.container">
    <div :class="$style.logoContainer">
      <router-link to="/">
        <img :src="require('@/assets/img/logo-sm.png')" alt="" />
      </router-link>
    </div>

    <div class="mb-4">
      <Logout after-logout-url="/login" />
    </div>

    <h1 :class="$style.pageHeadline">DASHBOARD</h1>

    <div :class="$style.divider"></div>

    <main :class="$style.main">
      <div v-if="fetchUserIsrcUploadStatusPending">
        <BaseSpinner show />
      </div>
      <div v-else-if="fetchUserIsrcUploadStatusError">
        There was a problem while fetching your data.
      </div>
      <template v-else-if="fetchUserIsrcUploadStatusSuccess">
        <form>
          <div
            :class="[
              $style.fileDropArea,
              isFileUploadDragActive && $style.isActive,
            ]"
          >
            <div :class="$style.fileMsg">
              <BaseButton
                variant="primary-solid"
                :class="$style.uploadCtaButton"
                type="button"
              >
                <span>
                  Add files here
                  <BaseSpinner :show="processUploadedFileStatusPending" />
                </span>
              </BaseButton>
              <span>or drop CSV or excel files to upload</span>
              <p
                v-if="processUploadedFileStatusError"
                :class="$style.uploadErrorMsg"
              >
                {{ uploadErrorMessage }}
              </p>
            </div>
            <input
              ref="uploadInput"
              :class="[$style.fileInput]"
              type="file"
              accept=".csv,.xls"
              multiple
              @dragenter="activateUploadDrag"
              @focus="activateUploadDrag"
              @click="activateUploadDrag"
              @dragleave="deactivateUploadDrag"
              @blur="deactivateUploadDrag"
              @drop="deactivateUploadDrag"
              @change="uploadFile"
              :disabled="processUploadedFileStatusPending"
            />
          </div>
        </form>
        <div>
          <div :class="$style.filesSection">
            <ul :class="$style.filesList">
              <FileListItem
                v-for="(upload, idx) of uploads"
                :key="upload.id || idx"
                :upload="upload"
                :deleteUserIsrcUploadStatus="deleteUserIsrcUploadStatus"
                :reprocessIsrcUploadStatus="reprocessIsrcUploadStatus"
                @deleteUpload="deleteUpload"
                @updateUploadDoc="updateUploadDoc"
                @reprocessUpload="reprocessUpload($event, idx)"
              />
            </ul>
          </div>
        </div>
      </template>
    </main>
  </div>
</template>
<script>
import { onAuthStateChanged } from "@/api/authApi";
import {
  processFileUploadIsrcCodes,
  fetchUserIsrcUploads,
  deleteUserIsrcUpload,
  scheduleFileUploadReprocess,
} from "@/api/isrcApi";
import {
  API_STATUS,
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
} from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import FileListItem from "./components/FileListItem";
import { read, utils } from "xlsx";
import Logout from "@/components/menu/Logout";
/* load the codepage support library for extended support with older formats  */
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);
export default {
  components: {
    FileListItem,
    Logout,
  },
  data() {
    return {
      user: null,
      uploads: [],
      fetchUserIsrcUploadStatus: IDLE,
      processUploadedFileStatus: IDLE,
      reprocessIsrcUploadStatus: {},
      deleteUserIsrcUploadStatus: {},
      isFileUploadDragActive: false,
      uploadErrorMessage: "",
    };
  },
  async created() {
    this.API_STATUS = API_STATUS;
    const user = await onAuthStateChanged();
    if (!user) {
      this.$router.push("/login");
      return;
    }
    this.user = user;
    this.fetchUploadedFilesData();
  },
  mounted() {},
  computed: {
    ...apiStatusComputedFactory("fetchUserIsrcUploadStatus"),
    ...apiStatusComputedFactory("processUploadedFileStatus"),
  },
  methods: {
    updateUploadDoc(doc) {
      for (let i = 0; i < this.uploads.length; i++) {
        const item = this.uploads[i];
        if (item.id === doc.id) {
          this.$set(this.uploads, i, {
            ...item,
            ...doc,
          });
          break;
        }
      }
    },
    activateUploadDrag() {
      this.isFileUploadDragActive = true;
    },
    deactivateUploadDrag() {
      this.isFileUploadDragActive = false;
    },
    async deleteUpload(fileUploadedId) {
      try {
        const shouldDelete = confirm(
          "Are you sure you want to delete this file?"
        );
        if (!shouldDelete) return;
        this.$set(this.deleteUserIsrcUploadStatus, fileUploadedId, PENDING);
        await deleteUserIsrcUpload(fileUploadedId);
        this.uploads = this.uploads.filter(({ id }) => id !== fileUploadedId);
        this.$set(this.deleteUserIsrcUploadStatus, fileUploadedId, SUCCESS);
      } catch (error) {
        this.$set(this.deleteUserIsrcUploadStatus, fileUploadedId, ERROR);
        console.error(error);
        alert(
          "There was a problem while deleting the file. Please try again or contact support."
        );
      }
    },
    async fetchUploadedFilesData() {
      try {
        this.fetchUserIsrcUploadStatus = PENDING;
        const uploads = await fetchUserIsrcUploads();
        if (Array.isArray(uploads) && uploads.length) {
          const numPlaceholders = 10 - uploads.length;

          this.uploads =
            numPlaceholders > 0
              ? [
                  ...uploads,
                  ...Array.from({ length: numPlaceholders }).map(() => ({
                    placeholder: true,
                  })),
                ]
              : uploads;
        } else {
          this.uploads = Array.from({ length: 10 }).map(() => ({
            placeholder: true,
          }));
        }
        this.fetchUserIsrcUploadStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.fetchUserIsrcUploadStatus = ERROR;
      }
    },
    processFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (file.name.endsWith("xls") || file.name.endsWith("xlsx")) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsText(file);
        }
        reader.onload = async (event) => {
          let parsedData;
          if (file.name.endsWith("xls") || file.name.endsWith("xlsx")) {
            const workbook = await read(event.target.result, {
              type: "binary",
            });
            let worksheetIsrcData = [];
            workbook.SheetNames.forEach(function (sheetName) {
              // Here is your object
              worksheetIsrcData.push(
                utils
                  .sheet_to_row_object_array(workbook.Sheets[sheetName])
                  .map(({ isrc }) => isrc)
              );
            });
            parsedData = worksheetIsrcData.flat();
          } else {
            parsedData = event.target.result.split("\n");
          }
          const [, ...data] = parsedData;
          const isrcIndex = 0;
          // let isrcCodesCache = {};
          const isrcCodes = new Set();
          for (const row of data) {
            const code = row
              .split(",")
              [isrcIndex]?.replaceAll("'", "")
              ?.replaceAll('"', "")
              ?.replace("\r", "")
              .trim();
            if (!code || isrcCodes.has(code)) continue;
            isrcCodes.add(code);
          }
          const isrc_ids = [...isrcCodes.values()];
          if (isrc_ids.length > 500000) {
            reject("TOO_MANY_ITEMS");
          }
          try {
            const result = await processFileUploadIsrcCodes({
              isrc_ids,
              fileName: file.name,
            });
            console.log("res", result);
            const {
              fileUploadedId,
              fileName,
              matchedPercentage,
              unmatchedPercentage,
              matchedCodesCount,
              unmatchedCodesCount,
              status,
              userId,
            } = result;
            const uploadItem = {
              id: fileUploadedId,
              fileName,
              matchedPercentage,
              unmatchedPercentage,
              matchedCodesCount,
              unmatchedCodesCount,
              status,
              userId,
            };
            // Uploads array can have placeholders
            // We need to find the index of the first placeholder
            // so we can replace it
            if (this.uploads.at(-1).placeholder) {
              let nearestPlaceholderItemIndex = null;
              for (let i = 0; i < this.uploads.length; i++) {
                if (this.uploads[i].placeholder) {
                  nearestPlaceholderItemIndex = i;
                  break;
                }
              }
              this.uploads.pop();
            }
            this.uploads.unshift(uploadItem);

            resolve(true);
          } catch (error) {
            console.error(error);
            reject();
          }
        };
        reader.onerror = reject;
      });
    },
    devProcessFiles() {
      if (!this.files) {
        alert("Upload a file");
        return;
      }
      this.files.forEach(this.processFile);
    },

    async reprocessUpload(fileUploadedId, uploadIdx) {
      try {
        if (this.reprocessIsrcUploadStatus[fileUploadedId] === PENDING) return;
        this.$set(this.reprocessIsrcUploadStatus, fileUploadedId, PENDING);
        await scheduleFileUploadReprocess({ fileUploadedId });
        this.$set(this.uploads[uploadIdx], "status", "PENDING");
        this.$set(this.reprocessIsrcUploadStatus, fileUploadedId, SUCCESS);
      } catch (error) {
        console.error(error);
        this.$set(this.reprocessIsrcUploadStatus, fileUploadedId, ERROR);
      }
    },
    async uploadFile(e) {
      if (!e.target.files.length) return;
      console.log("files", e.target.files);

      try {
        this.processUploadedFileStatus = PENDING;
        this.files = Array.from(e.target.files);
        await Promise.all(
          Array.from(e.target.files).map((file) => this.processFile(file))
        );
        this.processUploadedFileStatus = SUCCESS;
      } catch (error) {
        if (error === "TOO_MANY_ITEMS") {
          this.uploadErrorMessage = "You can't upload more than 50.000 items.";
        } else {
          this.uploadErrorMessage =
            "There was a problem while fetching your uploads.";
        }
        this.processUploadedFileStatus = ERROR;
      }
    },
  },
};
</script>
<style lang="scss" module>
.container {
  max-width: 1180px;
  padding: 0 2rem;
  margin: 5rem auto 0 auto;
}

.logoContainer {
  display: flex;
  justify-content: flex-end;
  a {
    display: flex;
    justify-content: flex-end;
  }
  img {
    max-width: 80%;
  }
}

.pageHeadline {
  color: #45507f;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.divider {
  background: hsla(217, 25%, 80%, 0.1065);
  height: 40px;
  width: 100%;
  margin-bottom: 2.5rem;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.uploadForm {
  flex-basis: 30%;
}

.filesSection {
  flex-basis: 60%;
}

.uploadCtaButton {
  border-radius: 10px;
}

.uploadErrorMsg {
  color: red;
}

.filesList {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  margin: 0;
  padding: 0;
  height: calc(324px + 1rem);
  overflow: auto;
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.fileDropArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 25px;
  border: 1px solid #bfc9d9;
  border-radius: 3px;
  transition: 0.2s;
  &.isActive {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.fileMsg {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  text-align: center;
  color: hsla(274, 77%, 71%, 65.16);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fileInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
</style>