<template>
  <li :class="$style.filesListItem">
    <div :class="$style.filesListItemContent">
      <div v-if="upload.status === 'ERROR'">
        <p>There was a problem while processing the file. Please try again.</p>
      </div>
      <div v-else>
        <div
          v-if="upload.status === 'PENDING'"
          :class="$style.pendingProcessingContainer"
        >
          <BaseSpinner show />
          <span :class="$style.processingFileText">Processing...</span>
        </div>
        <div v-else class="ring">
          <svg
            :class="$style.radialSvg"
            :data-percentage="upload.matchedPercentage"
            viewBox="0 0 80 80"
          >
            <circle
              :class="[$style.circle, $style.circleIncomplete]"
              cx="40"
              cy="40"
              r="35"
            ></circle>
            <circle
              :class="[$style.circle, $style.circleComplete]"
              cx="40"
              cy="40"
              r="35"
              :style="getCompleteCircleStyle(upload.matchedPercentage)"
            ></circle>
            <text
              :class="$style.matchedCount"
              x="50%"
              y="57%"
              transform="matrix(0, 1, -1, 0, 80, 0)"
            >
              {{
                upload.placeholder ? 0 : Math.round(upload.matchedPercentage)
              }}%
            </text>
          </svg>
        </div>
        <component
          :is="upload.status === 'PENDING' ? 'span' : 'router-link'"
          v-if="!upload.placeholder"
          :to="`/search/${upload.id}`"
          :class="$style.filesListItemText"
          >{{ upload.fileName }}</component
        >
        <div :class="$style.matchedTracksText">
          {{ upload.matchedCodesCount }} unique tracks
        </div>
        <div v-if="!upload.placeholder" :class="$style.filesListItemActions">
          <BaseButton
            @click.prevent="$emit('deleteUpload', upload.id)"
            variant="icon"
            :disabled="
              deleteUserIsrcUploadStatus[upload.id] === API_STATUS.PENDING
            "
          >
            <BaseIcon
              :class="$style.uploadListItemIcon"
              :icon="
                deleteUserIsrcUploadStatus[upload.id] === API_STATUS.PENDING
                  ? 'spinner'
                  : 'trash'
              "
              :spin="
                deleteUserIsrcUploadStatus[upload.id] === API_STATUS.PENDING
              "
            />
          </BaseButton>
          <BaseButton
            @click.prevent="$emit('reprocessUpload', upload.id)"
            variant="icon"
            :disabled="
              upload.status === 'PENDING' ||
              reprocessIsrcUploadStatus[upload.id] === API_STATUS.PENDING
            "
          >
            <BaseIcon
              :class="$style.uploadListItemIcon"
              :icon="
                reprocessIsrcUploadStatus[upload.id] === API_STATUS.PENDING
                  ? 'spinner'
                  : 'sync'
              "
              :spin="reprocessIsrcUploadStatus[upload.id] === API_STATUS.PENDING"
            />
          </BaseButton>
          <!-- <BaseButton
            tag="router-link"
            variant="icon"
            :to="`/search/${upload.id}`"
          >
            <BaseIcon :class="$style.uploadListItemIcon" icon="eye" />
          </BaseButton> -->
        </div>
      </div>
    </div>
  </li>
</template>

<script>
const getCompleteCircleStyle = (matchedPercentage) => {
  const radius = 35;
  const circumference = 2 * Math.PI * radius;
  const strokeDashOffset =
    circumference - (matchedPercentage * circumference) / 100;

  return {
    "stroke-dashoffset": strokeDashOffset,
  };
};
import {
  API_STATUS,
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
} from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import { checkIfFileProcessingFinished } from "@/api/isrcApi";

export default {
  name: "FileListItem",
  props: {
    upload: {
      type: Object,
      required: true,
    },
    deleteUserIsrcUploadStatus: {
      type: Object,
      default: () => ({}),
    },
    reprocessIsrcUploadStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.TRIES = 0;
    this.API_STATUS = API_STATUS;
    // if (this.upload.status === "PENDING") {
    //   this.checkIfFileProcessingFinished();
    // }
  },
  data() {
    return {
      checkingFileProcessingStatus: IDLE,
    };
  },
  watch: {
    "upload.status": {
      immediate: true,
      handler(status) {
        if (status === "PENDING") {
          this.checkIfFileProcessingFinished();
        }
      },
    },
  },
  computed: {
    ...apiStatusComputedFactory("checkingFileProcessingStatus"),
  },
  methods: {
    getCompleteCircleStyle,
    async checkIfFileProcessingFinished() {
      this.checkingFileProcessingStatus = PENDING;
      const { response, error } = await withAsync(() =>
        checkIfFileProcessingFinished({
          fileUploadedId: this.upload.id,
          userId: this.upload.userId,
        })
      );
      console.log("response", response);

      if (error || !response) {
        this.checkingFileProcessingStatus = ERROR;
        console.error(error);
        // Check why the status didn't change to error
        this.$emit("updateUploadDoc", { status: "ERROR" });
        return;
      }

      this.$emit("updateUploadDoc", response.doc);
      if (response.ready) {
        this.checkingFileProcessingStatus = SUCCESS;
        return;
      }
      this.TRIES++;
      if (this.TRIES === 500) {
        console.log("Run out of tries");
        this.checkingFileProcessingStatus = ERROR;
        return;
      }

      this.checkIfProcessedTimeout = setTimeout(() => {
        this.checkIfFileProcessingFinished();
      }, 5000);
    },
  },
  beforeDestroy() {
    clearTimeout(this.checkIfProcessedTimeout);
  },
};
</script>

<style lang="scss" module>
.filesListItem {
  border: 1px solid #bfc9d9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.filesListItemContent {
  padding: 1rem 1.5rem;
}

.pendingProcessingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.processingFileText {
  font-size: 0.8rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  display: block;
}

.filesListItemText {
  padding-bottom: 0.5rem;
  display: block;
  margin: 0 auto;
  font-size: 0.8rem;
  text-align: center;
  word-break: break-word;
}

.filesListItemActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* radial progress */
.radialSvg {
  height: auto;
  max-width: 200px;
  padding: 1em;
  transform: rotate(-90deg);
  width: 50px;
  display: block;
  margin: 0 auto;
}

.radialSvg .circle {
  fill: rgba(0, 0, 0, 0);
  stroke: #79e3c3;
  stroke-dashoffset: 219.91148575129;
  stroke-width: 4;
}

.radialSvg .circleIncomplete {
  stroke: #dcc6f4;
}

.radialSvg .circleComplete {
  stroke-dasharray: 219.91148575129;
}

.radialSvg text {
  fill: #fff;
  text-anchor: middle;
}

.matchedCount {
  fill: #373a3c !important;
}

.matchedTracksText {
  color: #bd7eee;
  text-align: center;
  font-size: 0.8rem;
}

.uploadListItemIcon {
  font-size: 1rem;
  color: #cfd0df;

  &:hover {
    color: hsla(274, 77%, 71%, 65.16);
  }
}
</style>